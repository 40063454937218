import React from 'react'

import { Box } from 'ui/components/Box'

import { useSystemNavigationState } from './hooks/useSystemNavigationState'
import * as Parts from './Navigation.parts'
import { SystemNavigationItem } from './SystemNavigationItem'
import { SystemNavigationItemFavorites } from './SystemNavigationItemFavorites'
import { SystemNavigationItemNotifications } from './SystemNavigationItemNotifications'
import { SystemNavigationItemSearch } from './SystemNavigationItemSearch'

type SystemNavigationProps = {
    isCollapsed?: boolean
}

export const SystemNavigation: React.FC<SystemNavigationProps> = ({ isCollapsed }) => {
    const {
        showSearch,
        showNotifications,
        showTasks,
        showFavorites,
        showHome,
        showSystemNavigation,
        homePath,
        tasksPath,
    } = useSystemNavigationState()

    if (!showSystemNavigation) return null

    return (
        <Box
            flex
            column
            alignSelf="stretch"
            position="relative"
            style={{ zIndex: 1 }}
            px={isCollapsed ? 's' : 'xs'}
        >
            {showSearch && (
                <Box flex px="xs" alignSelf="stretch">
                    <SystemNavigationItemSearch isCollapsed={isCollapsed} />
                </Box>
            )}
            <Parts.SystemNavigationWrapper
                isCollapsed={isCollapsed}
                mt={showSearch ? (isCollapsed ? 'm' : 'xl') : 0}
            >
                {showHome && (
                    <SystemNavigationItem
                        iconName="home-02"
                        label="Home"
                        to={homePath}
                        isCollapsed={isCollapsed}
                        aria-label="Home"
                    />
                )}
                {showNotifications && (
                    <SystemNavigationItemNotifications isCollapsed={isCollapsed} />
                )}
                {showTasks && (
                    <SystemNavigationItem
                        iconName="checkmark-square-02"
                        label="Tasks"
                        to={tasksPath}
                        isCollapsed={isCollapsed}
                        aria-label="Tasks"
                    />
                )}
                {showFavorites && <SystemNavigationItemFavorites isCollapsed={isCollapsed} />}
            </Parts.SystemNavigationWrapper>
        </Box>
    )
}
