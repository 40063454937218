import { useCallback, useMemo } from 'react'

import { useNavigationContext } from './useNavigationContext'

export function useMainNavigationState() {
    const {
        workspaceNavState,
        mainNavState,
        selectedApp,
        isPortal,
        toggleMobileMainNavigation,
        showTrialBanner,
        permissions,
    } = useNavigationContext()

    const isNavHidden =
        mainNavState === 'collapsed' ||
        mainNavState === 'mobile-collapsed' ||
        (!selectedApp && !isPortal)

    const isWorkspaceNavHidden = workspaceNavState === 'hidden'
    const showMobileControls = mainNavState === 'mobile-expanded' && !isWorkspaceNavHidden

    const onBackClick = useCallback(() => {
        toggleMobileMainNavigation(false)
    }, [toggleMobileMainNavigation])

    const showHelpAndSupport = permissions.canViewHelpAndSupport

    return useMemo(
        () => ({
            isNavHidden,
            isPortal,
            showMobileControls,
            onBackClick,
            showTrialBanner,
            showHelpAndSupport,
            isWorkspaceNavHidden,
        }),
        [
            isNavHidden,
            isPortal,
            onBackClick,
            showHelpAndSupport,
            showMobileControls,
            showTrialBanner,
            isWorkspaceNavHidden,
        ]
    )
}
