import md5 from 'md5'

import { useAppContext } from 'app/useAppContext'
import { invalidateFields } from 'data/hooks/fields'
import { invalidateNavigation } from 'data/hooks/navigation'
import { invalidateObjects } from 'data/hooks/objects/objectOperations'
import { invalidatePages } from 'data/hooks/pages'
import { invalidateViews } from 'data/hooks/views'

import { useRealtimeUpdates } from './realtimeUpdates'

export const RealtimeUpdatesWatcher = (): null => {
    const { selectedStack } = useAppContext()
    useRealtimeUpdates({
        channel: selectedStack ? md5(selectedStack._sid) : '',
        disabled: !selectedStack,
        handler: (event) => {
            if (event === 'object_schema_imported') {
                invalidateObjects()
                invalidateFields()
                invalidateNavigation()
                invalidateViews()
                invalidatePages()
            }
        },
    })

    return null
}
