import { useEffect } from 'react'

import { isPortalUser } from 'features/auth/utils/roleUtils'

import { Rights } from './accountUserContextConstants'
import { useAccountUserContext } from './useAccountUserContext'

const APP_ID = 'ff138c3e-9043-400a-8972-1ae09ad2a5ef'

type ChatSettings = {
    app_id: string
    email: string
    name: string
    avatar_url?: string
    // 👇 Add any additional custom fields here
}

const _bootPylon = (chatSettings: ChatSettings) => {
    try {
        //@ts-expect-error
        if (window.Pylon) {
            //@ts-expect-error
            window.pylon = { chat_settings: chatSettings }
        } else {
            setTimeout(() => {
                _bootPylon(chatSettings)
            }, 3000)
        }
    } catch (err) {
        console.error('Unable to boot Pylon', err)
    }
}

export const PylonChatBot: React.FC = () => {
    const { user, hasRight } = useAccountUserContext()

    useEffect(() => {
        if (user && !isPortalUser(user) && hasRight(Rights.ContactSupport)) {
            const chatSettings = {
                app_id: APP_ID,
                email: user.email,
                name: user.name,
                avatar_url: user.avatar,
            }

            _bootPylon(chatSettings)
        }
    }, [user, hasRight])

    return null
}
