export const TRANSLATIONS: Record<ExternalIntegrationId, Record<string, string>> = {
    airtable: {
        Database: 'Base',
        databases: 'bases',
        database: 'base',
        tables: 'tables',
        table: 'table',
    },
} as const

export const INTEGRATION_ID_TO_NAME: Record<ExternalIntegrationId, string> = {
    airtable: 'Airtable',
} as const

export const INTEGRATION_ID_TO_DC_TYPE: Record<ExternalIntegrationId, DataConnectionType> = {
    airtable: 'nango_airtable',
} as const

export const NEW_DATA_CONNECTION_MODAL_KEY = 'NewDataConnectionModal'
