import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'
import { getFieldIcon } from 'features/admin/fields/icons/utils'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'

import { DataGridHeaderComponent } from './types'

import {
    DataGridFieldHeaderButtonStyle,
    DataGridFieldHeaderMenuButtonStyle,
    DataGridFieldHeaderStyle,
} from './DataGridFieldHeader.css'

const sortDirectionIcon: Record<string, IconName> = {
    asc: 'ArrowDown',
    desc: 'ArrowUp',
}

type DataGridFieldHeaderProps = {
    field: FieldDto
    onFieldEdited: (field: FieldDto) => void
    orderBy: { id: string; desc?: boolean }
    setOrderBy: (orderBy?: { id: string; desc?: boolean }) => void
    allowFieldEdit: boolean
}

export const DataGridFieldHeader: DataGridHeaderComponent<DataGridFieldHeaderProps> = ({
    displayName,
    field,
    context,
    onFieldEdited,
    eGridHeader,
    orderBy,
    setOrderBy,
    allowFieldEdit,
}) => {
    const sortDirection = useMemo(() => {
        const isSortingByThisColumn = field.api_name === orderBy?.id
        if (isSortingByThisColumn) {
            return orderBy?.desc ? 'desc' : 'asc'
        }

        return null
    }, [field.api_name, orderBy?.desc, orderBy?.id])

    const sortIcon = sortDirection ? sortDirectionIcon[sortDirection] : null

    const onSortRequested = useCallback(() => {
        switch (sortDirection) {
            case 'asc':
                // Switch to descending.
                setOrderBy({ id: field.api_name, desc: true })
                break
            case 'desc':
                // Disable sorting.
                setOrderBy()
                break
            case null:
                // Switch to ascending.
                setOrderBy({ id: field.api_name })
                break
        }
    }, [field.api_name, setOrderBy, sortDirection])

    useLayoutEffect(() => {
        const handler = (e: KeyboardEvent) => {
            // Handle keyboard interaction with the column header.
            if (e.key === 'Enter') {
                if (e.ctrlKey) {
                    setIsFieldEditorOpen(true)
                } else {
                    onSortRequested()
                }
            }
        }

        eGridHeader.addEventListener('keydown', handler)

        return () => eGridHeader.removeEventListener('keydown', handler)
    }, [eGridHeader, onSortRequested])

    const containerRef = useRef<HTMLDivElement>(null)
    const anchorRef = useRef<HTMLButtonElement>(null)

    const [isFieldEditorOpen, setIsFieldEditorOpen] = useState(false)
    const onHeaderMenuClick = useCallback(() => {
        setIsFieldEditorOpen((prev) => !prev)
    }, [])

    const fieldIcon = getFieldIcon(field)

    return (
        <Box
            height="full"
            width="full"
            ref={containerRef}
            flex
            center
            gap="xs"
            justifyContent="space-between"
            className={DataGridFieldHeaderStyle}
            position="relative"
        >
            <Box flex center width="full" grow shrink>
                <Button
                    type="button"
                    variant="ghost"
                    size="xs"
                    color="textWeak"
                    fontWeight="bodyBold"
                    className={DataGridFieldHeaderButtonStyle}
                    onClick={onSortRequested}
                    startIcon={fieldIcon?.type === 'lucide' ? fieldIcon : undefined}
                >
                    {displayName}
                </Button>
                {sortIcon && <Icon name={sortIcon} size="s" ml="xs" color="textWeakest" />}
            </Box>
            {allowFieldEdit && (
                <Box flex center className={DataGridFieldHeaderMenuButtonStyle({})}>
                    <Button
                        ref={anchorRef}
                        type="button"
                        startIcon={{ name: 'ChevronDown' }}
                        onClick={onHeaderMenuClick}
                        variant="ghost"
                        size="xs"
                        color="textWeakest"
                        aria-expanded={isFieldEditorOpen}
                    />
                </Box>
            )}

            <FieldEditorPopover
                open={isFieldEditorOpen}
                placement="bottom-start"
                container={containerRef.current as HTMLElement}
                target={anchorRef.current as HTMLElement}
                objectId={context.object._sid}
                field={field}
                onSuccess={(updatedField) => {
                    setIsFieldEditorOpen(false)
                    onFieldEdited(updatedField)
                }}
                onCancel={() => {
                    setIsFieldEditorOpen(false)
                }}
                onClose={() => {
                    setIsFieldEditorOpen(false)
                }}
            />
        </Box>
    )
}
