import { useMemo } from 'react'

import { getWorkspaceUrl, Urls } from 'app/UrlService'
import useLDFlags from 'data/hooks/useLDFlags'

import { useNavigationContext } from './useNavigationContext'

export function useSystemNavigationState() {
    const { permissions, workspaceAccount, internalZone } = useNavigationContext()
    const { flags } = useLDFlags()

    const showSearch = flags.search
    const showNotifications = flags.notifications
    const showTasks = flags.tasks
    const showFavorites = flags.favorites

    const showHome = permissions.canViewInternalZone

    const showSystemNavigation = permissions.canViewInternalZone

    const homePath = getWorkspaceUrl(Urls.Home, workspaceAccount, internalZone)
    const tasksPath = getWorkspaceUrl(Urls.Tasks, workspaceAccount, internalZone)

    return useMemo(
        () => ({
            showSearch,
            showNotifications,
            showTasks,
            showFavorites,
            showHome,
            showSystemNavigation,
            homePath,
            tasksPath,
        }),
        [
            showSearch,
            showNotifications,
            showTasks,
            showFavorites,
            showHome,
            showSystemNavigation,
            homePath,
            tasksPath,
        ]
    )
}
