import React from 'react'

import { NavigationApp, NavigationCurrentUser, NavigationPortal, NavigationSpace } from './types'

export type NavigationContextValue = {
    workspaceNavState:
        | 'static'
        | 'collapsed'
        | 'expanded'
        | 'mobile-expanded'
        | 'mobile-collapsed'
        | 'hidden'
    setWorkspaceNavState: React.Dispatch<
        React.SetStateAction<NavigationContextValue['workspaceNavState']>
    >
    tryCollapseWorkspaceNav: () => void
    executeActionInApp: (app: NavigationApp, action: () => void) => void
    showCreateViewModal: (app: NavigationApp, display: ListViewDisplay) => void
    mainNavState: 'static' | 'collapsed' | 'mobile-expanded' | 'mobile-collapsed'
    workspaceAccount?: Account
    workspaceZone?: AccountZone
    internalZone?: AccountZone
    workspaceZones: AccountZone[]
    selectedApp?: NavigationApp
    workspaceAccountRole?: AccountRole
    workspaceAccounts: Account[]
    currentUser: NavigationCurrentUser
    isPortal: boolean
    isOnPortalDomain: boolean
    spaces: NavigationSpace[]
    portals: NavigationPortal[]
    showCreateAppModal: (spaceId?: string) => void
    openMobileNavigation: () => void
    closeMobileNavigation: () => void
    toggleMobileMainNavigation: (state: boolean) => void
    showTrialBanner: boolean
    permissions: {
        canEditWorkspaceSettings: boolean
        canEditAppSettings: boolean
        canEditAppUsers: boolean
        canDeleteLayout: boolean
        canDeleteApp: boolean
        canPinApp: boolean
        canCreateTable: boolean
        canCreateView: boolean
        canCreateCustomPage: boolean
        canEditViewLayout: boolean
        canDuplicateLayout: boolean
        canArchiveApp: boolean
        canChangeAppSpace: boolean
        canCreateApp: boolean
        canEditPortalSettings: boolean
        canCreatePortal: boolean
        canViewTrialBanner: boolean
        canViewInternalZone: boolean
        canViewHelpAndSupport: boolean
    }
}

// This context is used for the V2 navigation.
export const NavigationContext = React.createContext<NavigationContextValue | null>(null)
