import React from 'react'

import { Urls } from 'app/UrlService'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Dropdown,
    DropdownContent,
    DropdownItem,
    DropdownItemComplex,
    DropdownItemLink,
    DropdownSeparator,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
    DropdownTitle,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationCurrentUserDropdownState } from './hooks/useNavigationCurrentUserDropdownState'
import { WorkspaceIcon } from './WorkspaceIcon'

import { NavigationAvatarStyle, NavigationWorkspaceDropdownIconStyle } from './Navigation.css'

type NavigationCurrentUserDropdownProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isCollapsed?: boolean
}

export const NavigationCurrentUserDropdown: React.FC<NavigationCurrentUserDropdownProps> = ({
    isCollapsed,
    ...props
}) => {
    const {
        fullName,
        email,
        firstName,
        lastName,
        type,
        imageUrl,
        isDropdownOpen,
        setIsDropdownOpen,
        onInteractOutside,
        onMouseEnter,
        onOpenAccountSettings,
        showAccountSettings,
        showWorkspaces,
        workspaces,
        canCreateWorkspaces,
        createWorkspace,
        currentWorkspaceSid,
        currentWorkspaceName,
        hasInternalZoneAccess,
    } = useNavigationCurrentUserDropdownState({ isCollapsed })

    const dropdownSide = useResponsiveValue({
        mobile: 'top' as const,
        tablet: 'right' as const,
    })
    const dropdownAlignOffset = useResponsiveValue({
        mobile: 0,
        tablet: isCollapsed ? 0 : 58,
    })
    const dropdownSideOffset = useResponsiveValue({
        mobile: 0,
        tablet: isCollapsed ? 6 : -24,
    })

    return (
        <Box
            flex
            center
            noShrink
            background="surface"
            py="m"
            px={isCollapsed ? 0 : 'l'}
            width="full"
            {...props}
        >
            <Dropdown open={isDropdownOpen} onOpenChange={setIsDropdownOpen} modal={false}>
                <DropdownTrigger asChild onMouseEnter={onMouseEnter}>
                    <Box flex center justifyContent="space-between" role="button" width="full">
                        <Box
                            flex
                            center
                            gap="m"
                            grow
                            shrink
                            justifyContent={isCollapsed ? 'center' : 'flex-start'}
                        >
                            <Avatar
                                size="s"
                                className={NavigationAvatarStyle}
                                noShrink
                                type={type}
                                imageUrl={imageUrl!}
                                firstName={firstName}
                                lastName={lastName}
                            />
                            {!isCollapsed && (
                                <Box trim grow shrink>
                                    <Body size="m" weight="medium" trim grow shrink>
                                        {fullName}
                                    </Body>
                                </Box>
                            )}
                        </Box>
                        {!isCollapsed && (
                            <Button
                                variant="ghost"
                                size="xs"
                                startIcon={{ name: isDropdownOpen ? 'ChevronUp' : 'ChevronDown' }}
                                data-state={isDropdownOpen ? 'active' : 'inactive'}
                            />
                        )}
                    </Box>
                </DropdownTrigger>
                <DropdownContent
                    align="end"
                    side={dropdownSide}
                    alignOffset={dropdownAlignOffset}
                    sideOffset={dropdownSideOffset}
                    style={{ zIndex: 200, minWidth: '250px', maxWidth: '100%' }}
                    onInteractOutside={onInteractOutside}
                    onClick={stopPropagation}
                >
                    <DropdownItemComplex
                        label={fullName}
                        subtitle={email}
                        style={{ pointerEvents: 'none' }}
                        startAvatar={{
                            type,
                            imageUrl,
                            firstName,
                            lastName,
                        }}
                    />
                    {showWorkspaces && (
                        <>
                            <DropdownSeparator />
                            <DropdownSub>
                                <DropdownSubTrigger
                                    label={
                                        <Box
                                            trim
                                            style={{
                                                marginTop: '-2px',
                                                paddingTop: '2px',
                                                marginBottom: '-2px',
                                                paddingBottom: '2px',
                                            }}
                                        >
                                            <Body
                                                weight="bold"
                                                style={{
                                                    lineHeight: 'inherit',
                                                    fontSize: 'inherit',
                                                    color: 'inherit',
                                                }}
                                            >
                                                Workspace:
                                            </Body>{' '}
                                            <Body
                                                weight="regular"
                                                style={{
                                                    lineHeight: 'inherit',
                                                    fontSize: 'inherit',
                                                    color: 'inherit',
                                                }}
                                            >
                                                {currentWorkspaceName}
                                            </Body>
                                        </Box>
                                    }
                                />
                                <DropdownSubContent>
                                    <DropdownTitle>Workspaces</DropdownTitle>
                                    {workspaces.map((workspace) => {
                                        const isChecked = currentWorkspaceSid === workspace.id

                                        return (
                                            <DropdownItemLink
                                                key={workspace.id}
                                                href={workspace.url}
                                                label={workspace.name}
                                                checked={isChecked}
                                                multiSelect
                                                leftSlotContent={() => (
                                                    <WorkspaceIcon
                                                        workspaceAccount={workspace.account}
                                                        className={
                                                            NavigationWorkspaceDropdownIconStyle
                                                        }
                                                    />
                                                )}
                                            />
                                        )
                                    })}
                                    {canCreateWorkspaces && (
                                        <DropdownItem
                                            label="Create new workspace"
                                            startIcon={{ name: 'Plus' }}
                                            variant="action"
                                            onClick={createWorkspace}
                                        />
                                    )}
                                </DropdownSubContent>
                            </DropdownSub>
                        </>
                    )}
                    {showAccountSettings && (
                        <>
                            <DropdownSeparator />
                            <DropdownItem
                                onClick={onOpenAccountSettings}
                                label="Account settings"
                            />
                        </>
                    )}
                    {hasInternalZoneAccess && (
                        <>
                            <DropdownSeparator />
                            <DropdownItemLink
                                href="https://support.stackerhq.com/"
                                label="Help center"
                                target="_blank"
                            />
                            <DropdownItemLink
                                href="https://status.stacker.app/"
                                label="Status page"
                                target="_blank"
                            />
                        </>
                    )}
                    <DropdownSeparator />
                    <DropdownItemLink
                        variant="destructive"
                        label="Log out"
                        startIcon={{ name: 'LogOut' }}
                        to={Urls.Logout}
                    />
                </DropdownContent>
            </Dropdown>
        </Box>
    )
}
