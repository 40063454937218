import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

import { useToast } from 'ui/components/Toast'

type ValidateResponse = {
    is_valid: boolean
}

type useValidateAirtableConnectionInput = {
    nangoConnectionId: string
    integrationId: string
}
export function useValidateAirtableConnection(
    options: UseMutationOptions<ValidateResponse, unknown, useValidateAirtableConnectionInput> = {}
) {
    const toast = useToast()
    return useMutation(
        async ({ nangoConnectionId, integrationId }: useValidateAirtableConnectionInput) => {
            return fetchAndReturn('data-connections/validate-airtable-connection/', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    nango_connection_id: nangoConnectionId,
                    integration_id: integrationId,
                }),
            }) as Promise<ValidateResponse>
        },
        {
            ...options,
            onError: () => {
                toast({
                    title: "There's an existing airtable connection with the chosen airtable user. Please reuse the existing account and adjust bases available to it as appropriate.",
                    type: 'error',
                })
            },
        }
    )
}
