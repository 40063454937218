import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import useLDFlags from 'data/hooks/useLDFlags'

import { IconPickerDropdown } from 'ui/components/IconPicker/IconPickerDropdown'
import { RenderInlineElement } from 'ui/deprecated/forms/ui'

import { IconPickerForm as LegacyIconPickerForm } from './IconPicker'

type IconPickerFormProps = {
    name: string
    label?: string
}

export const IconPickerForm: React.FC<IconPickerFormProps> = ({ name, ...props }) => {
    const {
        control,
        formState: { errors },
        setValue,
    } = useFormContext()

    const iconError = errors[name]

    const { flags } = useLDFlags()

    if (!flags.navigationV2) {
        return <LegacyIconPickerForm name={name} {...props} />
    }

    return (
        // @ts-expect-error: JS compatibility issues.
        <RenderInlineElement error={iconError} style={{ alignItems: 'flex-start' }} {...props}>
            <Controller
                control={control}
                name={name}
                render={({ field }) => (
                    <IconPickerDropdown
                        variant="hugeicons"
                        value={field.value ? { name: field.value, type: 'hugeicons' } : undefined}
                        onChange={(newValue) => {
                            field.onChange(newValue?.name ?? null)
                            setValue('icon_family', newValue?.type ?? null)
                        }}
                    />
                )}
            />
        </RenderInlineElement>
    )
}
