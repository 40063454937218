import React, { memo } from 'react'

import classnames from 'classnames'

import { FavoriteType } from 'data/hooks/favorites/types'
import { SidebarAgentsPanel } from 'features/Agents/SidebarAgentsPanel'
import { StackIconBadge } from 'features/core/StackIconBadge'
import { TrialBanner } from 'features/core/TrialBanner'
import { useFavoritesEnabled } from 'features/favorites/useFavoritesEnabled'
import { useNavContext } from 'features/utils/useNavContext'
import { AddNewTableButton } from 'features/workspace/AddNewTableButton/AddNewTableButton'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import AppNavTree from 'features/workspace/Sidebar/AppNavTree'
import { ThemeConfig } from 'features/workspace/types'
import { wsNavThemeToV3 } from 'features/workspace/wsNavThemeToV3'

import { ScrollBox, Tooltip } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import V4DesignSystem from 'ui/deprecated/V4DesignSystem'

import { CollapseControls } from './CollapseControls'
import { FavoriteButton } from './FavoriteButton'
import { SidebarUserMenuButton } from './SidebarUserMenuButton'
import { WorkspaceSidebarTop } from './WorkspaceSidebarTop'

import { SidebarHoverClass } from './WorkspaceSidebarStyles.css'

type ApplicationSidebarProps = {
    navTheme: ThemeConfig
    stack: StackDto
    shouldShowWorkspaceControls?: boolean
}

export const ApplicationSidebar = memo<ApplicationSidebarProps>(function Sidebar({
    navTheme,
    stack,
    shouldShowWorkspaceControls,
}) {
    const { sidebarState, setSidebarState, isPoppedOut } = useNavContext()

    const { handleClickOutside: handleClickOutsideSidePane } = useSlidingPane()

    const favoritesEnabled = useFavoritesEnabled()

    return (
        <Box
            flex
            column
            flexGrow={1}
            position="relative"
            alignItems="stretch"
            onClick={handleClickOutsideSidePane}
            maxWidth="full"
            borderWidth={0}
            borderColor="border"
            background="surface"
            borderStyle="base"
            borderRightWidth={1}
            className={classnames(ONBOARDING_CLASSES.APP_NAV_MENU, SidebarHoverClass)}
            style={{
                minWidth: 0,
            }}
            data-testid="app-nav-menu"
        >
            {shouldShowWorkspaceControls && (
                <>
                    <WorkspaceSidebarTop />
                    <Divider my="m" />
                </>
            )}
            <Box
                flex
                px="l"
                pt="m"
                pb="xs"
                pr="xs"
                style={{ color: navTheme.textBright }}
                justifyContent="space-between"
                center
                overflow="hidden"
            >
                <Box flex center shrink>
                    <StackIconBadge stack={stack} size={28} mr="m" mb="xs" />
                    <Tooltip
                        label={stack.name}
                        placement="right"
                        style={{
                            flexShrink: 1,
                            minWidth: 0,
                        }}
                    >
                        <div
                            style={{
                                ...V4DesignSystem.h2,
                                color: navTheme.textBright,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {stack.name}
                        </div>
                    </Tooltip>
                    {favoritesEnabled && (
                        <FavoriteButton
                            targetType={FavoriteType.App}
                            stack_id={stack._sid}
                            mb="xs"
                            ml="m"
                        />
                    )}
                </Box>
            </Box>
            <ScrollBox
                marginTop="10px"
                overflowY="auto"
                flexGrow={1}
                flexShrink={1}
                scrollbarColor={navTheme.highlightColor}
            >
                <SidebarAgentsPanel />
                <AppNavTree stack={stack} navTheme={wsNavThemeToV3(navTheme)} />
            </ScrollBox>
            <AddNewTableButton />
            <TrialBanner />
            {shouldShowWorkspaceControls && (
                <>
                    <Divider />
                    <Box noShrink>
                        <SidebarUserMenuButton navTheme={navTheme} />
                    </Box>
                </>
            )}
            {!shouldShowWorkspaceControls && (
                <CollapseControls
                    isCollapsed={sidebarState === 'fullyCollapsed'}
                    toggleCollapsed={() =>
                        setSidebarState(
                            sidebarState !== 'fullyCollapsed' ? 'fullyCollapsed' : 'open'
                        )
                    }
                    isPoppedOut={isPoppedOut}
                />
            )}
        </Box>
    )
})
