import { useCallback, useMemo } from 'react'

import { openWorkspaceSettingsModal } from 'features/utils/__hackyGlobalModalControls'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useNavigationContext } from './useNavigationContext'

export function useWorkspaceNavigationState() {
    const { permissions, isPortal, selectedApp, toggleMobileMainNavigation, showTrialBanner } =
        useNavigationContext()

    const { workspaceNavState, setWorkspaceNavState, tryCollapseWorkspaceNav } =
        useNavigationContext()

    const isWorkspaceNavCollapsed = workspaceNavState === 'collapsed'

    const toggleWorkspaceNavCollapsed = useCallback(() => {
        setWorkspaceNavState((prevState) => (prevState === 'collapsed' ? 'expanded' : 'collapsed'))
    }, [setWorkspaceNavState])

    const tooltipText = isWorkspaceNavCollapsed ? 'Expand navigation' : 'Collapse navigation'

    const hasAppSelected = !!selectedApp

    const showMainNavigationToggle = useResponsiveValue({
        mobile: hasAppSelected,
        tablet: false,
    })

    const canEditWorkspaceSettings =
        permissions.canEditWorkspaceSettings && !isPortal && !showMainNavigationToggle

    const handleOpenWorkspaceSettings = useCallback(() => {
        tryCollapseWorkspaceNav()
        openWorkspaceSettingsModal({ page: 'general' })
    }, [tryCollapseWorkspaceNav])

    const showCollapseToggle = useResponsiveValue({
        mobile: false,
        tablet: workspaceNavState !== 'static',
    })

    const showMainNavigation = useCallback(() => {
        toggleMobileMainNavigation(true)
    }, [toggleMobileMainNavigation])

    const isNavHidden = workspaceNavState === 'mobile-collapsed' || workspaceNavState === 'hidden'

    return useMemo(
        () => ({
            workspaceNavState,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            handleOpenWorkspaceSettings,
            canEditWorkspaceSettings,
            hasAppSelected,
            showMainNavigationToggle,
            showCollapseToggle,
            showMainNavigation,
            isNavHidden,
            showTrialBanner,
        }),
        [
            workspaceNavState,
            isWorkspaceNavCollapsed,
            toggleWorkspaceNavCollapsed,
            tooltipText,
            handleOpenWorkspaceSettings,
            canEditWorkspaceSettings,
            hasAppSelected,
            showMainNavigationToggle,
            showCollapseToggle,
            showMainNavigation,
            isNavHidden,
            showTrialBanner,
        ]
    )
}
